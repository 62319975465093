import React from 'react';
import logo from './logo.svg';
import './logo.css';




export default function Logo() {
  return (
    <div className='main_div'>
      <a href="https://mikeandmatttrade.co.uk">
      <img className='Logo_Login' src={logo} alt='School Engine Logo'></img>
      </a>
    </div>

  );
};