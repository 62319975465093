import React, { useState, useEffect } from 'react';
import './CustomerBasket.css';
import { ApiCall } from '../func.js';

export default function CustomerBasket(props) {

    const [data, setDataState] = useState(null);
    const [basketData, setBasketDataState] = useState(null);
    const [basketSubTotal, setBasketSubTotalState] = useState(0.00);
    const [isLoaded, setIsLoadedState] = useState(false);
    const [apiError, setApiErrorState] = useState(false);
    const [apiErrorText, setApiErrorTextState] = useState(null);

    async function apiCall() {
        var url = 'https://api.mikeandmatttrade.co.uk/prod/customer/basket';
        var params = {};
        try {
            const responseData = await ApiCall(url, params, setApiErrorState);
            setDataState(responseData[0]);
            let newSubTotal = 0.00;
            let internalData = JSON.parse(responseData[0]['items']);
            Object.entries(internalData).forEach(entry => {
                const [key, value] = entry;
                newSubTotal = value["totalPrice"] + newSubTotal;
            });
            setBasketSubTotalState(newSubTotal.toFixed(2));
            setBasketDataState(JSON.parse(responseData[0]['items']));
            setIsLoadedState(true);
        } catch (e) {
            console.log(e);
            setApiErrorState(true);
            setIsLoadedState(true);
            setApiErrorTextState(e);
        }
    }


    function updateItemQuantity(change, key) {
        let internalData = [...basketData]; // using spread operator as without it its just a link to the state not a copy of it
        let newSubTotal = 0.00;
        if (change == "positive") {
            let newQuantity = internalData[key]['quantity'] + 1;
            internalData[key]['quantity'] = newQuantity;
            internalData[key]['totalPrice'] = internalData[key]['unitPrice'] * newQuantity;
        } else if (change == "negative") {
            let newQuantity = internalData[key]['quantity'] - 1;
            internalData[key]['quantity'] = newQuantity;
            internalData[key]['totalPrice'] = internalData[key]['unitPrice'] * newQuantity;
        } else {
            console.log('error');
        }
        setBasketDataState(internalData);
        Object.entries(internalData).forEach(entry => {
            const [key, value] = entry;
            newSubTotal = value["totalPrice"] + newSubTotal;
        });

        setBasketSubTotalState(newSubTotal.toFixed(2));
    }

    useEffect(() => {
        apiCall();
    }, []);

    if (!isLoaded) {
        return (
            <h1>Loading</h1>
        )
    } else if (apiError) {
        return (
            <h1>Error</h1>
        )
    } else if (isLoaded && !apiError) {
        return (
            <div className="basket-main-div">
                <table className="BasketTable">
                    <tbody>
                        <tr className="basketTableHead">
                            <td className="basketTableHeadData">Item</td>
                            <td className="basketTableHeadData">Unit Price</td>
                            <td className="basketTableHeadData" colSpan={3}>Qty</td>
                            <td className="basketTableHeadData">Total Price</td>
                        </tr>
                        {basketData.map((item, index) => {
                            if (item.type == "text") {
                                return (
                                    <tr key={index} className="basketTableRow">
                                        <td className="basketTableCell">{item.materialThickness}{item.materialThickness ? 'mm' : ''} {item.material} "{item.text}" {item.font} Font {item.size}{item.size ? 'mm' : ''}</td>
                                        <td className="basketTableCell">£{item.unitPrice.toFixed(2)}</td>
                                        <td className="basketTableCellQty"><button className="basketDownQtyButton" onClick={() => updateItemQuantity('negative', index)}>-</button></td>
                                        <td className="basketTableCellQty">{item.quantity}</td>
                                        <td className="basketTableCellQty"><button className="basketUpQtyButton" onClick={() => updateItemQuantity('positive', index)}>+</button></td>
                                        <td className="basketTableCell">£{item.totalPrice.toFixed(2)}</td>
                                    </tr>
                                )
                            } else if (item.type == "custom") {
                                return (
                                    <tr key={index} className="basketTableRow">
                                        <td className="basketTableCell">{item.materialThickness}{item.materialThickness ? 'mm' : ''} {item.material} {item.Name} {item.size}{item.size ? 'mm' : ''}</td>
                                        <td className="basketTableCell">£{item.unitPrice.toFixed(2)}</td>
                                        <td className="basketTableCellQty"><button className="basketDownQtyButton" onClick={() => updateItemQuantity('negative', index)}>-</button></td>
                                        <td className="basketTableCellQty">{item.quantity}</td>
                                        <td className="basketTableCellQty"><button className="basketUpQtyButton" onClick={() => updateItemQuantity('positive', index)}>+</button></td>
                                        <td className="basketTableCell">£{item.totalPrice.toFixed(2)}</td>
                                    </tr>
                                )
                            } else if (item.type == "shape") {
                                return (
                                    <tr key={index} className="basketTableRow">
                                        <td className="basketTableCell">{item.materialThickness}{item.materialThickness ? 'mm' : ''} {item.material} {item.shapeName} {item.productCode} {item.size}{item.size ? 'mm' : ''}</td>
                                        <td className="basketTableCell">£{item.unitPrice.toFixed(2)}</td>
                                        <td className="basketTableCellQty"><button className="basketDownQtyButton" onClick={() => updateItemQuantity('negative', index)}>-</button></td>
                                        <td className="basketTableCellQty">{item.quantity}</td>
                                        <td className="basketTableCellQty"><button className="basketUpQtyButton" onClick={() => updateItemQuantity('positive', index)}>+</button></td>
                                        <td className="basketTableCell">£{item.totalPrice.toFixed(2)}</td>
                                    </tr>
                                )
                            } else {
                                return (
                                    <tr key={index} className="basketTableRow">
                                        <td className="basketTableCell">Error Processing Item - contact support</td>
                                        <td className="basketTableCell"></td>
                                        <td className="basketTableCell"></td>
                                        <td className="basketTableCell"></td>
                                        <td className="basketTableCell"></td>
                                        <td className="basketTableCell"></td>
                                    </tr>
                                )
                            }
                        }
                        )}
                        <tr className="basketTableTop">
                            <td className="basketTablePostage" colSpan={6}>Subtotal (Excluding Unknown Prices): £{basketSubTotal}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        )
    }


};
