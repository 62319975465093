import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { ApiCall } from '../func.js';
import { ViewOrderModal } from './OrderModals.js';
import './ProductCostingsTable.css';


export default function ProductCostingsTable(props) {

    const [data, setDataState] = useState(null);
    const [isLoaded, setIsLoadedState] = useState(false);
    const [apiError, setApiErrorState] = useState(false);
    const [apiErrorText, setApiErrorTextState] = useState(null);
    // modal appearence state
    const [modalActiveState, setModalActiveState] = useState(false);
    // modal customer data
    const [singleCostingDataState, setSingleCostingDataState] = useState(null);
    const [singleCostingErrorState, setSingleCostingErrorState] = useState(null);

    let history = useHistory();

    async function apiCall() {
        var url = 'https://api.mikeandmatttrade.co.uk/prod/admin/costing/allcostings';
        var params = {};
        try {
            const responseData = await ApiCall(url, params, setApiErrorState);
            setDataState(responseData);
            setIsLoadedState(true);
        } catch (e) {
            console.log(e);
            setApiErrorState(true);
            setIsLoadedState(true);
            setApiErrorTextState(e);
        }
    }

    useEffect(() => {
        apiCall();
    }, []);

    async function onRowClick(id) {
        console.log("row clicked" + id)
    };

    function modalClose() {
        setModalActiveState(false);
    };

    function addNewClicked() {
        history.push("/app/admin/product-costing/calculator");
    };

    if (!isLoaded) {
        return (
            <h1>Loading</h1>
        )
    } else if (apiError) {
        return (
            <h1>Error</h1>
        )
    } else if (isLoaded && !apiError) {

        return (
            <div className="ordersMainDiv">

                {//<ViewOrderModal modalActiveState={modalActiveState} setStateFunction={setModalActiveState} data={singleOrderDataState} onModalClose={modalClose} />
                }

                <h1>Product Costings</h1>
                <button onClick={() => addNewClicked()}>Add New Costing</button>
                <table className="ordersTable">
                    <thead>
                        <tr className="ordersTableHead">
                            <td className="ordersTableHeadData">Name</td>
                            <td className="ordersTableHeadData">Modified Date</td>
                            <td className="ordersTableHeadData">Cost Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => {
                            return (
                                <tr className="ordersTableRow" key={item.id} onClick={() => onRowClick(item.id)}>
                                    <td>{item.costingName}</td>
                                    <td>{item.modifiedDate}</td>
                                    <td>£{item.costingTotal}</td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>

            </div>
        )
    }
};