import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { ApiCall } from '../func.js';
import { ViewCustomerModal } from './CustomerModals.js';
import './Customers.css';

export default function AdminCustomers(props) {

    const [data, setDataState] = useState(null);
    const [isLoaded, setIsLoadedState] = useState(false);
    const [apiError, setApiErrorState] = useState(false);
    const [apiErrorText, setApiErrorTextState] = useState(null);
    // modal appearence state
    const [modalActiveState, setModalActiveState] = useState(false);
    // modal customer data
    const [singleCustomerDataState, setSingleCustomerDataState] = useState(null);
    const [singleCustomerErrorState, setSingleCustomerErrorState] = useState(null);

    let history = useHistory();

    async function apiCall() {
        var url = 'https://api.mikeandmatttrade.co.uk/prod/admin/customers';
        var params = {};
        try {
            const responseData = await ApiCall(url, params, setApiErrorState);
            setDataState(responseData);
            setIsLoadedState(true);
        } catch (e) {
            console.log(e);
            setApiErrorState(true);
            setIsLoadedState(true);
            setApiErrorTextState(e);
        }
    }

    useEffect(() => {
        apiCall();
    }, []);

    async function onRowClick(customerId) {
        var url = 'https://api.mikeandmatttrade.co.uk/prod/admin/customers/single';
        var params = {"id":customerId};
        try {
            const customerResponseData = await ApiCall(url, params, setSingleCustomerErrorState);
            setSingleCustomerDataState(customerResponseData);
            setModalActiveState(true);
            console.log(customerResponseData);
        } catch (e) {
            console.log(e);
            setSingleCustomerErrorState(true);
        }
    };

    function modalClose() {
          setModalActiveState(false);
      };

    if (!isLoaded) {
        return (
            <h1>Loading</h1>
        )
    } else if (apiError) {
        return (
            <h1>Error</h1>
        )
    } else if (isLoaded && !apiError) {

        return (
            <div className="customersMainDiv">

                <ViewCustomerModal modalActiveState={modalActiveState} setStateFunction={setModalActiveState} data={singleCustomerDataState} onModalClose={modalClose} />

                <h1>Customers</h1>

                <table className="customersTable">
                    <thead>
                        <tr className="customersTableHead">
                            <td className="customersTableHeadData">CustomerId Name</td><td className="customersTableHeadData">Customer ID</td><td className="customersTableHeadData">Customer Email</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((customer) => {
                            return (

                                <tr className="customersTableRow" key={customer.customerId} onClick={() => onRowClick(customer.customerId)}>
                                    <td>{customer.customerName}</td>
                                    <td>{customer.customerId}</td>
                                    <td>{customer.customerEmail}</td>
                                </tr>

                            )
                        }
                        )}
                    </tbody>
                </table>

            </div>
        )
    }
};