export const Container = {'marginTop':'4vh'};
export const FormContainer = {'boxShadow': '0px 0px 0px 0 rgba(0,0,0,0)', 'marginTop':'6vh'};
export const FormSection = {'boxShadow': '0px 0px 0px 0 rgba(0,0,0,0)', 'backgroundColor': '#000000', 'borderRadius':'13px' }
export const FormField = {'boxShadow': '0px 0px 0px 0 rgba(0,0,0,0)'};
export const SectionHeader = {'display' : 'none'};
export const SectionBody = {'backgroundColor': '#ffffff', 'borderRadius':'7px', 'padding': '20px'};
export const SectionFooter = { 'display':'block', 'overflow': 'hidden', 'textAlign': 'center'};
export const SectionFooterPrimaryContent = { };
export const SectionFooterSecondaryContent = { 'display':'block', 'padding':'15px'};
export const Input = {'fontFamily': 'Verdana, Geneva, sans-serif', 'fontSize':'16px', 'textAlign':'center'};
export const Button = {'backgroundColor':'#ffffff', 'color':'#000000', 'marginTop':'30px', 'fontFamily': 'Verdana, Geneva, sans-serif', 'fontSize':'16px', 'borderRadius':'6px'};
export const PhotoPickerButton = {};
export const PhotoPlaceholder = {};
export const SignInButton = {};
export const SignInButtonIcon = {};
export const SignInButtonContent = {};
export const Strike = {};
export const StrikeContent = {};
export const ActionRow = {};
export const FormRow = {};
export const A = {};
export const Hint = {};
export const Radio = {};
export const Label = {};
export const InputLabel = {'fontFamily': 'Verdana, Geneva, sans-serif'};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const Toast = {};
export const NavBar = {};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {};

const AmplifyTheme = {
	container: Container,
	formContainer: FormContainer,
	formSection: FormSection,
	formField: FormField,

	sectionHeader: SectionHeader,
	sectionBody: SectionBody,
	sectionFooter: SectionFooter,
	sectionFooterPrimaryContent: SectionFooterPrimaryContent,
	sectionFooterSecondaryContent: SectionFooterSecondaryContent,

	input: Input,
	button: Button,
	photoPickerButton: PhotoPickerButton,
	photoPlaceholder: PhotoPlaceholder,
	signInButton: SignInButton,
	signInButtonIcon: SignInButtonIcon,
	signInButtonContent: SignInButtonContent,
	amazonSignInButton: AmazonSignInButton,
	facebookSignInButton: FacebookSignInButton,
	googleSignInButton: GoogleSignInButton,
	oAuthSignInButton: OAuthSignInButton,

	formRow: FormRow,
	strike: Strike,
	strikeContent: StrikeContent,
	actionRow: ActionRow,
	a: A,

	hint: Hint,
	radio: Radio,
	label: Label,
	inputLabel: InputLabel,
	toast: Toast,

	navBar: NavBar,
	nav: Nav,
	navRight: NavRight,
	navItem: NavItem,
	navButton: NavButton,
};

export default AmplifyTheme;