import React, { useState, useEffect } from 'react';
import AdminSidebar from './sidebar.js';
import './AdminApp.css';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import AdminProducts from './Products.js';
import AdminOrders from './Orders.js';
import AdminCustomers from './Customers.js';
import AdminDashboard from './AdminDashboard.js';
import ProductCosting from './ProductCosting.js';

export default function AdminApp(props) {

    return (
        <div>
            <AdminSidebar fullSidebarValue={props.fullSidebarValue} setFullSidebarState={props.setFullSidebarState} desktopView={props.desktopView} />
            <div className="adminMainDiv">
                <Switch>
                    <Route exact path="/app/admin">
                        <AdminDashboard />
                    </Route>
                    <Route path="/app/admin/customers">
                        <AdminCustomers />
                    </Route>
                    <Route path="/app/admin/orders">
                        <AdminOrders />
                    </Route>
                    <Route path="/app/admin/products">
                        <AdminProducts />
                    </Route>
                    <Route path="/app/admin/product-costing">
                        <ProductCosting />
                    </Route>
                    <Route>
                        <Redirect to="/app/admin" />
                    </Route>
                </Switch>

            </div>


        </div>
    )
};