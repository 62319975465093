import React, { useState, useEffect } from 'react';
import './CustomerShop.css';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import CustomText from "./CustomText.js"

export default function CustomerShop(props) {

    return (
        <div className="MainShopDiv">
            <h1>Shop</h1>

            <CustomText />
        </div>
    )
};