import { Auth } from 'aws-amplify';
import { FaCommentsDollar } from 'react-icons/fa';
import { userAppData } from './MainApp.js';

function handleErrors(response, apiErrorStateSet) {
    if (!response.ok) {
        apiErrorStateSet('error');
    }
    return response;
};

async function generateNewToken() {
    const response = await Auth.currentAuthenticatedUser({ bypassCache: true });
    let newToken = response["signInUserSession"]["idToken"]["jwtToken"];
    userAppData['idToken'] = newToken;
    return newToken;
};

export function ApiCall(primaryUrl, params, apiErrorStateSet) {
    return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser({ bypassCache: true }) // get new auth token
            .then(user => {
                let newToken = user["signInUserSession"]["idToken"]["jwtToken"];
                userAppData['idToken'] = newToken;
                // add params to url
                const entries = Object.entries(params);
                let url = primaryUrl;
                if (entries.length > 0) {
                    let count = 0;
                    for (const [name, value] of entries) {
                        if (count == 0) {
                            url = url + '?' + name + '=' + value;
                            count = count + 1;
                        } else {
                            url = url + '&' + name + '=' + value;
                            count = count + 1;
                        }
                    };
                };
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': newToken
                    },
                }).then(response => handleErrors(response, apiErrorStateSet))
                    .then(response => response.json())
                    .then(data => {
                        resolve(data);
                    }).catch(error => { // catches failed to fetch errors such as no internet when submit button is clicked
                        apiErrorStateSet(true);
                        resolve(error);
                    })
            })
    }).catch(err => console.log("Error Refreshing Token or Error with API Call"));
};


export async function ApiPost(primaryUrl, body, params, apiErrorStateSet) {
    try {
        const token = await generateNewToken();
        // add params to url
        const entries = Object.entries(params);
        let url = primaryUrl;
        if (entries.length > 0) {
            let count = 0;
            for (const [name, value] of entries) {
                if (count == 0) {
                    url = url + '?' + name + '=' + value;
                    count = count + 1;
                } else {
                    url = url + '&' + name + '=' + value;
                    count = count + 1;
                }
            };
        };
        let jsonBody = JSON.stringify(body);
        const submit = await fetch(url, { method: 'POST', body: jsonBody, headers: { 'Authorization': token } });
        console.log(submit);
        const response = handleErrors(submit, apiErrorStateSet);
        const data = response.json();
        return (data);
    } catch (e) {
        apiErrorStateSet('error');
        console.log(e);
    }
};