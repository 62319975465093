import React, { useState, useEffect } from 'react';

export default function AdminProducts(props) {

    return (
        <div>
            <h1>Products</h1>
            
        </div>
    )
};