import React, { useState, useEffect } from 'react';
import Amplify, { Auth, Storage } from 'aws-amplify';
import './CustomerDashboard.css';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";

export default function CustomerDashboard(props) {

    Amplify.configure({
        Storage: {
            AWSS3: {
                bucket: 'mikeandmatttrade-images', //REQUIRED -  Amazon S3 bucket name
                region: 'eu-west-2', //OPTIONAL -  Amazon service region
            }
        }
    });

    return (
        <div>
            <h1>Dashboard</h1>
        </div>
    )
};