import React, { useState, useEffect } from 'react';
import './CustomText.css';
import { ApiCall } from '../func.js';
import HowToSizeImage from './assets/Font Sizing Image.svg';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";

export default function CustomText(props) {

    const [data, setDataState] = useState(null);
    const [isLoaded, setIsLoadedState] = useState(false);
    const [apiError, setApiErrorState] = useState(false);
    const [apiErrorText, setApiErrorTextState] = useState(null);

    const [mdfButtonState, setMdfButtonState] = useState(false);
    const [plyButtonState, setPlyButtonState] = useState(false);

    const [materialState, setMaterialState] = useState(null);
    const [thicknessState, setThicknessState] = useState(null);
    const [textState, setTextState] = useState(null);
    const [fontState, setFontState] = useState(null);
    const [sizeState, setSizeState] = useState(null);
    const [pricePossibleState, setPricePossibleState] = useState(false);
    const [priceState, setPriceState] = useState("0.00");

    const [mdfButtonClassState, setMdfButtonClassState] = useState("customTextItemDiv");
    const [plyButtonClassState, setPlyButtonClassState] = useState("customTextItemDiv");

    const [mdf3mmButtonState, setMdf3mmButtonState] = useState(false);
    const [mdf4mmButtonState, setMdf4mmButtonState] = useState(false);
    const [mdf6mmButtonState, setMdf6mmButtonState] = useState(false);

    const [mdf3mmButtonClassState, setMdf3mmButtonClassState] = useState("customTextThicknessDiv");
    const [mdf4mmButtonClassState, setMdf4mmButtonClassState] = useState("customTextThicknessDiv");
    const [mdf6mmButtonClassState, setMdf6mmButtonClassState] = useState("customTextThicknessDiv");


    async function apiCall() {
        var url = 'https://api.mikeandmatttrade.co.uk/prod/pricing';
        var params = { "type": "text" };
        try {
            const responseData = await ApiCall(url, params, setApiErrorState);
            let objectData = JSON.parse(responseData["variations"]);
            console.log(typeof (objectData));
            console.log(objectData);
            setDataState(objectData);
            setIsLoadedState(true);
        } catch (e) {
            console.log(e);
            setApiErrorState(true);
            setIsLoadedState(true);
            setApiErrorTextState(e);
        }
    }

    function mdfButtonClick() {
        setMaterialState("Premium MDF");
        setMdfButtonClassState("customTextItemDivSelected");
        setPlyButtonClassState("customTextItemDiv");
        setPlyButtonState(false);
        setMdfButtonState(true);
    }

    function plyButtonClick() {
        setMaterialState("Birch Plywood");
        setMdfButtonClassState("customTextItemDiv");
        setPlyButtonClassState("customTextItemDivSelected");
        setMdfButtonState(false);
        setPlyButtonState(true);
    }

    function mdf3mmButtonClick() {
        setThicknessState("3mm");
        setMdf4mmButtonClassState("customTextThicknessDiv");
        setMdf6mmButtonClassState("customTextThicknessDiv");
        setMdf3mmButtonClassState("customTextThicknessDivSelected");
        setMdf4mmButtonState(false);
        setMdf6mmButtonState(false);
        setMdf3mmButtonState(true);
    }

    function mdf4mmButtonClick() {
        setThicknessState("4mm");
        setMdf3mmButtonClassState("customTextThicknessDiv");
        setMdf6mmButtonClassState("customTextThicknessDiv");
        setMdf4mmButtonClassState("customTextThicknessDivSelected");
        setMdf3mmButtonState(false);
        setMdf6mmButtonState(false);
        setMdf4mmButtonState(true);
    }

    function mdf6mmButtonClick() {
        setThicknessState("6mm");
        setMdf3mmButtonClassState("customTextThicknessDiv");
        setMdf4mmButtonClassState("customTextThicknessDiv");
        setMdf6mmButtonClassState("customTextThicknessDivSelected");
        setMdf3mmButtonState(false);
        setMdf4mmButtonState(false);
        setMdf6mmButtonState(true);
    }

    
    function onChangeCalculatePrice() {
        //check both inputs have values
        let text = document.getElementById('textInput').value;
        let textSize = document.getElementById('sizeInput').value;
        let font = document.getElementById('fontSelector').value;
;        //check both inputs have values
        if (textSize == '' || text == '' || font == '') {
            console.log("empty value detected");
            setPriceState('0.00');
            setPricePossibleState(false);
        } else {
            let range = null;
            let textReplaced = text.replace(/\s/g, '');
            textReplaced = textReplaced.replace(/'/g, '');
            textReplaced = textReplaced.replace(/-/g, '');
            textReplaced = textReplaced.replace(/`/g, '');
            textReplaced = textReplaced.replace(/\./g, '');
            textReplaced = textReplaced.replace(/,/g, '');

            let textLength = textReplaced.length;
            if (textSize > 0 && textSize < 20) {
                range = "range1";
            } else if (textSize > 20 && textSize <= 30) {
                range = "range2";
            } else if (textSize > 30 && textSize <= 50) {
                range = "range3";
            } else if (textSize > 50 && textSize <= 70) {
                range = "range4";
            } else if (textSize > 70 && textSize <= 100) {
                range = "range5";
            } else if (textSize > 100 && textSize <= 150) {
                range = "range6";
            } else if (textSize > 150 && textSize <= 200) {
                range = "range7";
            } else if (textSize > 200 && textSize <= 250) {
                range = "range8";
            } else if (textSize > 250 && textSize <= 300) {
                range = "range9";
            } else if (textSize > 300 && textSize <= 350) {
                range = "range10";
            } else if (textSize > 350 && textSize <= 400) {
                range = "range11";
            } else {
                range = null;
            }
            let price = data[materialState][thicknessState][range];

            let calculatedPrice = (textLength * price).toFixed(2);
            setPriceState(calculatedPrice.toString());
            setPricePossibleState(true);
        }
    }


    useEffect(() => {
        apiCall();
    }, []);


    if (!isLoaded) {
        return (
            <h1>Loading Prices</h1>
        )
    } else if (apiError) {
        return (
            <h1>Error</h1>
        )
    } else if (isLoaded && !apiError) {

        return (
            <div className="customTextMainDiv">

                <h1>Custom Text</h1>

                <div className='customTextRowDiv'>
                    <h1 className='customTextHeading'>Material</h1>
                    <div className='customTextRowButtonsDiv'>
                        <div className={mdfButtonClassState} onClick={() => mdfButtonClick()}>
                            <h2 className="customTextItemDivText">Premium MDF</h2>
                        </div>
                        <div className={plyButtonClassState} onClick={() => plyButtonClick()}>
                            <h2 className="customTextItemDivText">Birch Ply</h2>
                        </div>
                    </div>
                </div>

                <div className={materialState ? 'customTextRowDiv' : 'customTextRowDivHidden'}>
                    <h1 className='customTextHeading'>Thickness</h1>
                    <div className='customTextRowButtonsDiv'>
                        <div className={mdf3mmButtonClassState} onClick={() => mdf3mmButtonClick()}>
                            <h2 className="customTextItemDivText">3mm</h2>
                        </div>
                        <div className={mdf4mmButtonClassState} onClick={() => mdf4mmButtonClick()}>
                            <h2 className="customTextItemDivText">4mm</h2>
                        </div>
                        <div className={mdf6mmButtonClassState} onClick={() => mdf6mmButtonClick()}>
                            <h2 className="customTextItemDivText">6mm</h2>
                        </div>
                    </div>
                </div>

                <div className={thicknessState ? 'customTextRowDiv' : 'customTextRowDivHidden'}>
                    <h1 className='customTextHeading'>Text</h1>
                    {//<img className='textSizingImage' src={HowToSizeImage} alt='Sizing Diagram' />
                    }
                    <div className='customTextRowButtonsDiv'>
                        <div className='customTextInputDiv'>
                            <h2 className='customTextInputHeading'>Text</h2>
                            <input id='textInput' className='customTextInput' onChange={onChangeCalculatePrice} />
                        </div>
                        <div className='customTextInputDiv'>
                            <h2 className='customTextInputHeading'>Size</h2>
                            <input id='sizeInput' type='number' className='customTextSizeInput' onChange={onChangeCalculatePrice} /> mm
                        </div>
                    </div>

                    <div className='customTextRowButtonsDiv'>
                        <div className='customTextInputDiv'>
                            <h1 className='customTextHeading'>Font</h1>
                            <h1 className='customTextHeadingNotes'>Use the selector below to pick a common font or choose custom and enter the font name in the notes section below.</h1>
                            <select id='fontSelector' onChange={onChangeCalculatePrice}>
                                <option value="">-Please choose an option-</option>
                                <option value="Grafolia Script">Grafolia Script</option>
                                <option value="Wicker Heavy SF">Wicker Heavy SF</option>
                                <option value="Cami Rae">Cami Rae</option>
                                <option value="Eds Market Upright Script">Eds Market Upright Script</option>
                                <option value="ScriptoramaMarkdownJF">ScriptoramaMarkdownJF</option>
                                <option value="Custom">Custom</option>
                            </select>
                        </div>
                    </div>

                    <div className='customTextRowButtonsDiv'>
                        <div className='customTextInputDiv'>
                            <h1 className='customTextHeading'>Custom Text Notes</h1>
                            <h1 className='customTextHeadingNotes'>Enter any notes or infomation specific to this item of text in the box below.</h1>
                            <textarea className='customTextNotesTextArea'></textarea>
                        </div>
                    </div>
                </div>

                <div className={pricePossibleState ? 'customTextRowDiv' : 'customTextRowDivHidden'}>
                    <h1 className='priceHeading'>£{priceState}</h1>
                    <button>Add To Basket</button>
                </div>


            </div>
        )
    }

};


