import React, { useState, useEffect } from 'react';
import './sidebar.css';
import { Auth } from 'aws-amplify';
import fullLogo from '../../logo.svg';
import { useHistory } from "react-router-dom";
import { userAppData } from '../MainApp.js';
import minLogo from '../../min_logo.svg';

import { IoPeopleSharp } from 'react-icons/io5';
import { BiLogOut, BiError } from 'react-icons/bi';
import { FiMinimize2 } from 'react-icons/fi';
import { FaShoppingBasket } from 'react-icons/fa';
import { HiDocumentDuplicate } from 'react-icons/hi';
import { BsArrowsAngleExpand } from 'react-icons/bs';
import { VscAccount } from 'react-icons/vsc'
import { AiOutlineShop } from 'react-icons/ai';





function iconFinder(icon, desktopView) {
    let size = '45px';
    if (!desktopView) {
        size = '35px';
    }

    switch (icon) {
        case 'Shop':
            return (
                <AiOutlineShop color='#ffffff' size={size} />
            );
        case 'Orders':
            return (
                <HiDocumentDuplicate color='#ffffff' size={size} />
            );
        case 'Basket':
            return (
                <FaShoppingBasket color='#ffffff' size={size} />
            );
        case 'Account':
            return (
                <VscAccount color='#ffffff' size={size} />
            );
        default:
            return (
                <BiError color='#ffffff' size={size} />
            );
    }
}





function SidebarItemWithDropdown(props) {
    let history = useHistory();

    function onDropdownItemClick(url) {
        history.push(url);
        if (!props.desktopViewState) { // if mobile view
            let currentState = props.dropdownState;
            props.dropdownStateChange(props.name, currentState);
            props.setFullSidebarState(false);
        }
    }

    function onMainItemClick() {
        let currentState = props.dropdownState;
        props.dropdownStateChange(props.name, currentState);
    }

    return (
        <div className="sidebar-item-padding-div">
            <div className='sidebar-item'>
                <div className='sidebar-item-main-item' onClick={() => onMainItemClick()}>
                    {iconFinder(props.name, props.desktopViewState)}
                    <span className={props.fullSidebarValue ? 'sidebar-item-text' : 'sidebar-item-text-min'}>{props.name}</span>
                </div>

                <div className={props.dropdownState ? 'sidebar-item-dropdown-outer' : 'sidebar-item-dropdown-outer-hide'}>
                    {props.dropdowns.map((dropdownitem) => {
                        return (
                            <div className='sidebar-item-dropdown-item' onClick={() => (onDropdownItemClick(dropdownitem["url"]))}>
                                <span className={props.fullSidebarValue ? 'sidebar-dropdown-item-text' : 'sidebar-item-text-min'}>{dropdownitem["name"]}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}





function SidebarItemWithoutDropdown(props) {
    let history = useHistory();

    function onItemClick(url) {
        console.log(props.desktopViewState);
        history.push(url)
        props.closeAllDropdowns();
        if (!props.desktopViewState) { // if mobile view
            let currentState = props.dropdownState;
            {//props.dropdownStateChange(props.name, currentState);
            }
            console.log("mobile view clicked");
            props.setFullSidebarState(false);
        }
    }

    return (
        <div className="sidebar-item-padding-div" onClick={() => onItemClick(props.url)}>
            <div className='sidebar-item'>
                <div className='sidebar-item-main-item'>
                    {iconFinder(props.name, props.desktopViewState)}
                    <span className={props.fullSidebarValue ? 'sidebar-item-text' : 'sidebar-item-text-min'}>{props.name}</span>
                </div>
            </div>
        </div>
    )
}


export default function AdminSidebar(props) {

    let history = useHistory();

    function returnToDashboard() {
        history.push('/app/dashboard')
    }

    function toggleSidebarVisibilityStatus() { // toggles between full and minimised sidebar

        if (props.fullSidebarValue) {
            props.setFullSidebarState(false);
        } else {
            props.setFullSidebarState(true);
        }
    }

    function closeAllDropdowns() {

    }


    function handleDropdownTrigger(name, currentState) {
        props.setFullSidebarState(true);
    }

    async function onSignOutClick() {
        await Auth.signOut()
    }




    return (
        <div className={props.fullSidebarValue ? 'sidebar-full' : 'sidebar-minimised'}>
            <div className='sidebar-logo-div' onClick={() => returnToDashboard()}><img className={props.fullSidebarValue ? 'sidebar-logo-full' : 'sidebar-logo-min'} src={props.fullSidebarValue ? fullLogo : minLogo} alt='logo' /></div>

            <div>
                <SidebarItemWithoutDropdown name="Shop" fullSidebarValue={props.fullSidebarValue} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/app/customer/shop" />
            </div>

            <div>
                <SidebarItemWithoutDropdown name="Orders" fullSidebarValue={props.fullSidebarValue} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/app/customer/orders" />
            </div>

            <div>
                <SidebarItemWithoutDropdown name="Account" fullSidebarValue={props.fullSidebarValue} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/app/customer/account" />
            </div>

            <div>
                <SidebarItemWithoutDropdown name="Basket" fullSidebarValue={props.fullSidebarValue} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/app/customer/basket" />
            </div>

            <div className='sidebar-minimise-div' onClick={() => toggleSidebarVisibilityStatus()}>
                {props.fullSidebarValue ? <FiMinimize2 color='#ffffff' size='35px' /> : <BsArrowsAngleExpand color='#ffffff' size='35px' />};
                <span className={props.fullSidebarValue ? 'sidebar-bottom-item-text' : 'sidebar-item-text-min'}>Minimise Sidebar</span>
            </div>
            <div className='sidebar-signout-div' onClick={() => onSignOutClick()}>
                <BiLogOut color='#ffffff' size='35px' />
                <span className={props.fullSidebarValue ? 'sidebar-bottom-item-text' : 'sidebar-item-text-min'}>Sign Out</span>
            </div>

        </div>
    )
}