import React, { useState, useEffect } from 'react';
import AdminSidebar from './sidebar.js';
import './AdminApp.css';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import ProductCostingCalc from './ProductCostingCalc.js';
import ProductCostingsTable from './ProductCostingsTable.js';

export default function ProductCosting(props) {

    return (
        <div>
            <Switch>
                <Route exact path="/app/admin/product-costing">
                    <ProductCostingsTable />
                </Route>
                <Route path="/app/admin/product-costing/calculator">
                    <ProductCostingCalc />
                </Route>
                <Route>
                    <ProductCostingsTable />
                </Route>
            </Switch>
        </div>
    )
};