import React, { useState, useEffect } from 'react';
import './CustomerAccount.css';
import { ApiCall } from '../func.js';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import { BsController } from 'react-icons/bs';

export default function CustomerAccount(props) {
    const [data, setDataState] = useState(null);
    const [isLoaded, setIsLoadedState] = useState(false);
    const [apiError, setApiErrorState] = useState(false);
    const [apiErrorText, setApiErrorTextState] = useState(null);

    let history = useHistory();

    async function apiCall() {
        var url = 'https://api.mikeandmatttrade.co.uk/prod/customer/account';
        var params = {};
        try {
            const responseData = await ApiCall(url, params, setApiErrorState);
            let stingToJson = JSON.parse(responseData["contacts"]);
            responseData["contacts"] = stingToJson;
            setDataState(responseData);
            setIsLoadedState(true);
        } catch (e) {
            console.log(e);
            setApiErrorState(true);
            setIsLoadedState(true);
            setApiErrorTextState(e);
        }
    }

    useEffect(() => {
        apiCall();
    }, []);



    if (!isLoaded) {
        return (
            <h1>Loading</h1>
        )
    } else if (apiError) {
        return (
            <h1>Error</h1>
        )
    } else if (isLoaded && !apiError) {

        return (
            <div className="customerAccountMainDiv">

                <h1>Account</h1>
                <div className="customerAccountDiv">

                    <div className="customerAccountRow">
                        <p className="customerAccountRowHeading">Customer Number: </p><p className="customerAccountRowData">{data.customerId}</p>
                    </div>

                    <div className="customerAccountRow">
                        <p className="customerAccountRowHeading">Business Name: </p><p className="customerAccountRowData">{data.customerName}</p>
                    </div>

                    <div className="customerAccountRow">
                        <p className="customerAccountRowHeading">Business Email: </p><p className="customerAccountRowData">{data.email}</p>
                    </div>

                    <div className="customerAccountRow">
                        <p className="customerAccountRowHeading">Primary Delivery Address: </p><p className="customerAccountRowData">{data.address}</p>
                    </div>

                    <div className="customerAccountRow">
                        <p className="customerAccountRowHeading">Contacts: </p>
                        {data.contacts.map((contact) => {
                            return (
                                <div>
                                    <p>Name:</p><p>{contact.name}</p>
                                    <p>Name:</p><p>{contact.email}</p>
                                    <p>Name:</p><p>{contact.phone}</p>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        )
    }
};