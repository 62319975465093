import React, { useState, useEffect, } from 'react';
import { useHistory } from "react-router-dom";
import './ProductCostingCalc.css';
import { ApiCall, ApiPost } from '../func.js';

function ItemRow(props) {
    const [internalTotalState, setInternalTotalState] = useState(0.00);

    function calculateGrandTotal(data) {
        let totalCost = 0
        for (let i = 0; i < data.length; i++) {
            totalCost = totalCost + data[i]["value"];
        }
        let grandTotal = (totalCost / 100).toFixed(2);
        props.setGrandTotalState(grandTotal);
    };

    function updatePrice(qty, id, price) {
        let quantity = parseFloat(qty);
        let calcPrice = (quantity * price);
        let totalPrice = calcPrice.toFixed(2);
        totalPrice = parseFloat(totalPrice);
        if (qty == 0) {
            let newGroupTotal = (props.internalGroupTotalState - internalTotalState).toFixed(2);
            props.setInternalGroupTotalState(newGroupTotal);
            delete props.topLevelArrayForPrices[id];
            setInternalTotalState(0.00);
            // update full data state
            let data = [...props.fullData];
            for (let i = 0; i < data.length; i++) {
                if (data[i]["id"] == props.id) {
                    data.splice(i);
                }
            }
            props.setFullData(data);
            calculateGrandTotal(data);

        } else { // when quantity is not 0
            let newGroupTotal = (props.internalGroupTotalState - internalTotalState).toFixed(2);
            setInternalTotalState(totalPrice);
            let groupTotal = parseFloat(newGroupTotal) + totalPrice;
            let roundedTotal = groupTotal.toFixed(2);
            props.setInternalGroupTotalState(roundedTotal);
            props.topLevelArrayForPrices[id] = totalPrice;

            // update full data state
            let newItem = { "id": props.id, "materialName": props.name, "qty": qty, "value": totalPrice }
            let data = [...props.fullData];
            if (Array.isArray(data) && data.length) { //if array is not empty
                const filtered = data.filter(n => n["id"] != props.id);
                filtered.push(newItem);
                data = filtered;
            } else { //if array is empty
                data.push(newItem);
            }
            props.setFullData(data);
            calculateGrandTotal(data);
        }

    }

    return (
        <tr className="ordersTableRow" key={props.id}>
            <td>{props.name}</td>
            <td><input onChange={(event) => updatePrice(event.target.value, props.id, props.pricePerUnit)}></input></td>
            <td>{props.pricePerUnit}</td>
            <td>{internalTotalState}</td>
        </tr>
    )
}

function GroupRow(props) {
    const [internalGroupTotalState, setInternalGroupTotalState] = useState(0.00);
    const [internalGroupShowState, setInternalGroupShowState] = useState("productCostingRowHideDiv");

    function toggleGroupShowState() {
        if (internalGroupShowState == "productCostingRowHideDiv") {
            setInternalGroupShowState("productCostingRowShowDiv");
        } else {
            setInternalGroupShowState("productCostingRowHideDiv");
        }
    }

    return (
        <div key={props.groupName}>
            <h2 onClick={() => toggleGroupShowState()}>{props.groupName} {internalGroupTotalState}</h2>
            <div className={internalGroupShowState}>
                <table className="ordersTable">
                    <thead>
                        <tr className="ordersTableHead">
                            <td className="ordersTableHeadData">Item</td>
                            <td className="ordersTableHeadData">Quantity</td>
                            <td className="ordersTableHeadData">Unit Price</td>
                            <td className="ordersTableHeadData">Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data[props.groupName].map((item) => {
                            return (
                                <ItemRow setGrandTotalState={props.setGrandTotalState} fullData={props.fullData} setFullData={props.setFullData} key={item.id} id={item.id} name={item.name} topLevelArrayForPrices={props.currentCostData} pricePerUnit={item.pricePerUnit} internalGroupTotalState={internalGroupTotalState} setInternalGroupTotalState={setInternalGroupTotalState} />
                            )
                        }
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}



export default function ProductCostingCalc(props) {

    const [data, setDataState] = useState(null);
    const [isLoaded, setIsLoadedState] = useState(false);
    const [apiError, setApiErrorState] = useState(false);
    const [apiErrorText, setApiErrorTextState] = useState(null);

    const [fullProductCostingDataState, setFullProductCostingDataState] = useState([]);
    const [grandTotalState, setGrandTotalState] = useState(0.00);

    const [postData, setPostDataState] = useState(null);
    const [postIsLoaded, setPostIsLoadedState] = useState(false);
    const [postApiError, setPostApiErrorState] = useState(false);
    const [postApiErrorText, setPostApiErrorTextState] = useState(null);

    let currentCostData = {};


    async function apiCall() {
        var url = 'https://api.mikeandmatttrade.co.uk/prod/admin/costing';
        var params = {};
        try {
            const responseData = await ApiCall(url, params, setApiErrorState);
            setDataState(responseData);
            setIsLoadedState(true);
        } catch (e) {
            console.log(e);
            setApiErrorState(true);
            setIsLoadedState(true);
            setApiErrorTextState(e);
        }
    }

    useEffect(() => {
        apiCall();
    }, []);


    let history = useHistory();

    function returnClicked() {
        history.push("/app/admin/product-costing");
    };

    async function onSaveClick() {
        let postData = {};
        //add input validation
        let itemName = document.getElementById("saveName").value;
        console.log(itemName);
        if (itemName == "") {
            console.log("Name Input Is Empty");
        } else {
            postData["itemName"] = itemName;
            postData["costingData"] = fullProductCostingDataState;
            postData["productTotal"] = grandTotalState;

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;
            postData["date"] = today;

            var url = 'https://api.mikeandmatttrade.co.uk/prod/admin/costing';
            var params = {};
            try {
                const postResponseData = await ApiPost(url, postData, params, setPostApiErrorState);
                console.log(postResponseData);
                setPostDataState(postResponseData);
                setPostIsLoadedState(true);
            } catch (e) {
                console.log(e);
                setPostApiErrorState(true);
                setPostIsLoadedState(true);
                setPostApiErrorTextState(e);
            }
        }


    }

    if (!isLoaded) {
        return (
            <h1>Loading</h1>
        )
    } else if (apiError) {
        return (
            <h1>Error</h1>
        )
    } else if (isLoaded && !apiError) {

        return (
            <div className="productCostingMainDiv">
                <h1>Product Costing System</h1>
                <button onClick={() => returnClicked()}>Return</button>
                <div className="productCostingMainSplitDiv">
                    <div className="productCostingLeftSplitDiv">
                        {data['GROUPS'].map((item) => {
                            return (
                                <GroupRow key={item.id} setGrandTotalState={setGrandTotalState} groupName={item} currentCostData={currentCostData} data={data} fullData={fullProductCostingDataState} setFullData={setFullProductCostingDataState} />
                            )
                        })}
                    </div>
                    <div className="productCostingRightSplitDiv">
                        <h2>Current Product Costing</h2>
                        <input id="saveName" type="text" />
                        <table className="ordersTable">
                            <thead>
                                <tr className="ordersTableHead">
                                    <td className="ordersTableHeadData">Item</td>
                                    <td className="ordersTableHeadData">Quantity</td>
                                    <td className="ordersTableHeadData">Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                {fullProductCostingDataState?.map((item, index) => {
                                    return (
                                        <tr className="ordersTableRow" key={index}>
                                            <td>{item["materialName"]}</td>
                                            <td>{item["qty"]}</td>
                                            <td>{item["value"]}</td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                        <h3>Total: £{grandTotalState}</h3>
                        <button onClick={() => onSaveClick()}>Save</button>
                    </div>
                </div>
            </div>
        )
    }

}

