import React from 'react';
import './OrderModals.css';

export function ViewOrderModal(props) {

    const modalHideCss = {
        display: "none",
        /* Hidden by default */
        position: "fixed",
        /* Stay in place */
        zIndex: "1",
        /* Sit on top */
        paddingTop: "150px",
        /* Location of the box */
        left: "0",
        top: "0",
        width: "100%",
        /* Full width */
        height: "100%",
        /* Full height */
        overflow: "auto",
        /* Enable scroll if needed */
        backgroundColor: "rgb(0, 0, 0)",
        /* Fallback color */
        backgroundColor: "rgba(0, 0, 0, 0.4)"
        /* Black w/ opacity */
    }

    const modalShowCss = {
        display: "block",
        /* Hidden by default */
        position: "fixed",
        /* Stay in place */
        zIndex: "1",
        /* Sit on top */
        paddingTop: "100px",
        paddingBottom: "100px",
        /* Location of the box */
        left: "0",
        top: "0",
        width: "100%",
        /* Full width */
        height: "100%",
        /* Full height */
        overflow: "auto",
        /* Enable scroll if needed */
        backgroundColor: "rgb(0, 0, 0)",
        /* Fallback color */
        backgroundColor: "rgba(0, 0, 0, 0.4)"
        /* Black w/ opacity */
    }

    let modalCss = modalHideCss;

    function hideModal() {
        props.setStateFunction(false);
        props.onModalClose();
    }

    if (props.modalActiveState == true) {
        modalCss = modalShowCss;
        let orderData = JSON.parse(props.data[0]["orderData"]);
        return (
            <div style={modalCss} className="order-modal">
                <div className="order-modal-content">
                    <div className="order-modal-header" onClick={hideModal}>
                        <span className="order-close" onClick={hideModal}>&times;</span>
                    </div>

                    <div className="order-modal-body">
                        {props.data.map((order) => {
                            return (
                                <div className="order-modal-body">
                                    <table className="orderTable" id="pdfDiv">
                                        <tbody>
                                            <tr className="orderTableTop">
                                                <td className="orderTableTopLeftData">Order {order.orderNumber}</td>
                                                <td></td>
                                                <td></td>
                                                <td className="orderTableTopRightData">{order.customerId}</td>

                                            </tr>
                                            <tr className="orderTableHead">
                                                <td className="orderTableHeadData">Item</td>
                                                <td className="orderTableHeadData">Unit Price</td>
                                                <td className="orderTableHeadData">Quantity</td>
                                                <td className="orderTableHeadData">Total Price</td>
                                            </tr>
                                            {orderData.map((item) => {
                                                if (item.type == "text") {
                                                    return (
                                                        <tr className="orderTableRow">
                                                            <td className="orderTableCell">{item.materialThickness} {item.material} "{item.text}" {item.font} Font {item.size}mm</td>
                                                            <td className="orderTableCell">£{item.unitPrice.toFixed(2)}</td>
                                                            <td className="orderTableCell">{item.quantity}</td>
                                                            <td className="orderTableCell">£{item.totalPrice.toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                } if (item.type == "custom") {
                                                    return (
                                                        <tr className="orderTableRow">
                                                            <td className="orderTableCell">{item.materialThickness} {item.material} {item.text} {item.Name} {item.size}mm</td>
                                                            <td className="orderTableCell">£{item.unitPrice.toFixed(2)}</td>
                                                            <td className="orderTableCell">{item.quantity}</td>
                                                            <td className="orderTableCell">£{item.totalPrice.toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (
                                                        <tr className="orderTableRow">
                                                            <td className="orderTableCell">Error Processing Item - contact support</td>
                                                            <td className="orderTableCell"></td>
                                                            <td className="orderTableCell"></td>
                                                            <td className="orderTableCell"></td>
                                                        </tr>
                                                    )
                                                }
                                            }
                                            )}
                                            <tr className="orderTableTop">
                                                <td className="orderTablePostage" colSpan={4}>P&P: £{order.postageCost}</td>
                                            </tr>
                                            <tr className="orderTableTop">
                                                <td className="orderTableTotal" colSpan={4}>Total: £{order.orderValue}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )
                        }
                        )}
                    </div>

                    <div className="order-modal-footer">
                    </div>
                </div>
            </div>
        )
    } else {
        modalCss = modalHideCss;
        return (
            <div style={modalCss} className="order-modal" onClick={hideModal}>
                <div className="order-modal-content">
                    <div className="order-modal-header">
                        <span className="order-close" onClick={hideModal}>&times;</span>
                    </div>

                    <div className="order-modal-body">
                        <h1 className='order-modalHeaderText'>Error</h1>
                        <h3 className='order-modalMessage'>No cutomer data recieved</h3>
                    </div>

                    <div className="order-modal-footer">

                    </div>
                </div>
            </div>
        )
    }
}