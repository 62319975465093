import React from 'react';
import './CustomerModal.css';


// modalActiveState setStateFunction requestType rid apiState onModalClose
export function ViewCustomerModal(props) {

  const modalHideCss = {
    display: "none",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "150px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.4)"
    /* Black w/ opacity */
  }

  const modalShowCss = {
    display: "block",
    /* Hidden by default */
    position: "fixed",
    /* Stay in place */
    zIndex: "1",
    /* Sit on top */
    paddingTop: "150px",
    /* Location of the box */
    left: "0",
    top: "0",
    width: "100%",
    /* Full width */
    height: "100%",
    /* Full height */
    overflow: "auto",
    /* Enable scroll if needed */
    backgroundColor: "rgb(0, 0, 0)",
    /* Fallback color */
    backgroundColor: "rgba(0, 0, 0, 0.4)"
    /* Black w/ opacity */
  }

  let modalCss = modalHideCss;

  function hideModal() {
    props.setStateFunction(false);
    props.onModalClose();
  }

  if (props.modalActiveState == true) {
    modalCss = modalShowCss;
    let contactsData = JSON.parse(props.data[0]["contacts"]);
    return (
      <div style={modalCss} className="modal" onClick={hideModal}>
        <div className="modal-content">
          <div className="modal-header">
            <span className="close" onClick={hideModal}>&times;</span>
          </div>

          <div className="modal-body">
            {props.data.map((customer) => {
              return (
                <div>
                  <p>{customer.customerName}</p>
                  <p>{customer.customerId}</p>
                  <p>{customer.customerEmail}</p>
                  {contactsData.map((contact) => {
                    return(
                      <div>
                        <p>{contact.name}</p>
                        <p>{contact.email}</p>
                        <p>{contact.phone}</p>
                      </div>
                    )
                  }
                  )}
                </div>
              )
            }
            )}
          </div>
          <div className="modal-footer">
          </div>
        </div>
      </div>
    )
  } else {
    modalCss = modalHideCss;
    return (
      <div style={modalCss} className="modal" onClick={hideModal}>
        <div className="modal-content">
          <div className="modal-header">
            <span className="close" onClick={hideModal}>&times;</span>
          </div>

          <div className="modal-body">
            <h1 className='modalHeaderText'>Error</h1>
            <h3 className='modalMessage'>No cutomer data recieved</h3>
          </div>

          <div className="modal-footer">

          </div>
        </div>
      </div>
    )
  }
}
