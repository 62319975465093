import React, { useState, useEffect } from 'react';
import './CustomerOrders.css';
import { useHistory } from "react-router-dom";
import { ApiCall } from '../func.js';
import { ViewOrderModal } from './CustomerOrderModals.js';

export default function CustomerOrders(props) {

    const [data, setDataState] = useState(null);
    const [isLoaded, setIsLoadedState] = useState(false);
    const [apiError, setApiErrorState] = useState(false);
    const [apiErrorText, setApiErrorTextState] = useState(null);
    // modal appearence state
    const [modalActiveState, setModalActiveState] = useState(false);
    // modal customer data
    const [singleOrderDataState, setSingleOrderDataState] = useState(null);
    const [singleOrderErrorState, setSingleOrderErrorState] = useState(null);

    let history = useHistory();

    async function apiCall() {
        var url = 'https://api.mikeandmatttrade.co.uk/prod/customer/orders';
        var params = {};
        try {
            const responseData = await ApiCall(url, params, setApiErrorState);
            setDataState(responseData);
            setIsLoadedState(true);
        } catch (e) {
            console.log(e);
            setApiErrorState(true);
            setIsLoadedState(true);
            setApiErrorTextState(e);
        }
    }

    useEffect(() => {
        apiCall();
    }, []);

    async function onRowClick(orderId) {
        var url = "https://api.mikeandmatttrade.co.uk/prod/customer/orders/single";
        var params = { "id": orderId };
        try {
            const orderResponseData = await ApiCall(url, params, setSingleOrderErrorState);
            setSingleOrderDataState(orderResponseData);
            setModalActiveState(true);
        } catch (e) {
            console.log(e);
            setSingleOrderErrorState(true);
        }
    };

    function modalClose() {
        setModalActiveState(false);
    };

    if (!isLoaded) {
        return (
            <h1>Loading</h1>
        )
    } else if (apiError) {
        return (
            <h1>Error</h1>
        )
    } else if (isLoaded && !apiError) {

        return (
            <div className="customerOrdersMainDiv">

                <ViewOrderModal modalActiveState={modalActiveState} setStateFunction={setModalActiveState} data={singleOrderDataState} onModalClose={modalClose} />

                <h1>Orders</h1>

                <table className="customerOrdersTable">
                    <thead>
                        <tr className="customerOrdersTableHead">
                            <td className="customerOrdersTableHeadData">Order Number</td>
                            <td className="customerOrdersTableHeadData">Order Value</td>
                            <td className="customerOrdersTableHeadData">Order Date</td>
                            <td className="customerOrdersTableHeadData">Order Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((order) => {
                            return (

                                <tr className="customerOrdersTableRow" key={order.id} onClick={() => onRowClick(order.id)}>
                                    <td className="customerOrdersTableData">{order.orderNumber}</td>
                                    <td className="customerOrdersTableData">£{order.orderValue}</td>
                                    <td className="customerOrdersTableData">{order.orderDate}</td>
                                    <td className="customerOrdersTableData">{order.orderStatus}</td>
                                </tr>

                            )
                        }
                        )}
                    </tbody>
                </table>

            </div>
        )
    }
};