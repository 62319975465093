/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:d3d925d1-bd6a-459a-847b-4330cea6cc37",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_kNDMH3XBS",
    "aws_user_pools_web_client_id": "6fsegt47aqq3obk1cque5umm16",
    "oauth": {},
    "aws_cognito_login_mechanism": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    }
};


export default awsmobile;
