import Logo from '../logo.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import '@aws-amplify/ui/dist/style.css';
import { SignIn, withAuthenticator, ForgotPassword } from 'aws-amplify-react';
import AmplifyTheme from '../AmplifyTheme';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import CustomerApp from './Customer/CustomerApp.js';
import AdminApp from './Admin/AdminApp.js';
import { ApiCall } from './func.js';

export const userAppData = [];

function MainApp() {

  const [desktopView, setDesktopViewState] = useState(true);  // used by sidebar to decide what size the icons are as mobile view icons are smaller
  const [fullSidebar, setFullSidebarState] = useState(true); // used to trigger the movement between full and minimised sidebar, if true sidebar is full
  const [data, setDataState] = useState(null);
  const [isLoaded, setIsLoadedState] = useState(false);
  const [apiError, setApiErrorState] = useState(false);
  const [apiErrorText, setApiErrorTextState] = useState(null);

  let history = useHistory();

  async function apiCall() {
    var url = 'https://api.mikeandmatttrade.co.uk/prod/perms/';
    var params = {};
    try {
      const responseData = await ApiCall(url, params, setApiErrorState);
      console.log(responseData);
      userAppData['displayName'] = responseData[0]['displayName'];
      userAppData['isAdmin'] = responseData[0]['isAdmin'];
      userAppData['email'] = responseData[0]['Email'];
      setIsLoadedState(true);
      setDataState(responseData);
      if (userAppData['isAdmin'] == 1) {
        history.push("/app/admin");
      } else {
        history.push("/app/customer");
      }

    } catch (e) {
      setApiErrorState(true);
      setIsLoadedState(true);
      setApiErrorTextState(e);
    }
  }

  useEffect(() => {
    let viewportwidth = window.innerWidth;
    if (viewportwidth <= 700) {
      setDesktopViewState(false);
      setFullSidebarState(false);
    }
    apiCall();
  }, []);

  if (!isLoaded) {
    return (
      <h1>Loading</h1>
    )
  } else if (apiError) {
    return (
      <h1>Error</h1>
    )
  } else if (isLoaded && !apiError) {
    return (
      <div>

        <div className="mainSite">
          <Switch>
            <Route path="/app/customer">
              <CustomerApp fullSidebarValue={fullSidebar} setFullSidebarState={setFullSidebarState} desktopView={desktopView} />
            </Route>
            <Route path="/app/admin">
              <AdminApp fullSidebarValue={fullSidebar} setFullSidebarState={setFullSidebarState} desktopView={desktopView} />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
};



export default withAuthenticator(MainApp, false, [
  <Logo />,
  <SignIn />,
  <ForgotPassword />
], null, AmplifyTheme);
