import React, { useState, useEffect } from 'react';
import CustomerSidebar from './sidebar.js';
import './CustomerApp.css';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import CustomerOrders from './CustomerOrders.js';
import CustomerShop from './CustomerShop.js';
import CustomerBasket from './CustomerBasket.js';
import CustomerDashboard from './CustomerDashboard.js';
import CustomerAccount from './CustomerAccount.js';

export default function CustomerApp(props) {

    return (
        <div>
            <CustomerSidebar fullSidebarValue={props.fullSidebarValue} setFullSidebarState={props.setFullSidebarState} desktopView={props.desktopView} />
            <div className="customerMainDiv">
                <Switch>
                    <Route exact path="/app/customer">
                        <CustomerDashboard />
                    </Route>
                    <Route path="/app/customer/orders">
                        <CustomerOrders />
                    </Route>
                    <Route path="/app/customer/shop">
                        <CustomerShop />
                    </Route>
                    <Route path="/app/customer/basket">
                        <CustomerBasket />
                    </Route>
                    <Route path="/app/customer/account">
                        <CustomerAccount />
                    </Route>
                    <Route>
                        <Redirect to="/app/customer" />
                    </Route>
                </Switch>

            </div>
        </div>
    )
};